.payment-method-page {
    position: relative;
    padding: 50px 0 100px;
    background-color: #fafafa;
}

.payment-method-page .left-content-area .form-block {
    position: relative;
}

.payment-method-page .left-content-area .form-block {
    position: relative;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 40px 10px rgba(0, 0, 0, 0.05);
    padding: 25px;
    margin-bottom: 30px;
}

.payment-method-page .left-content-area .form-block h3 {
    color: #000;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 0;
}


.payment-method-page .left-content-area .form-block .form-group label {
    color: #0F001A;
    color: var(--Text-color, #0F001A);
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-transform: capitalize;
    position: relative;
    background-color: #fff;
    margin-bottom: 0;
    top: 9px;
    z-index: 2;
    margin-left: 15px;
    padding: 5px 5px 0;
}

.payment-method-page .left-content-area .form-block .form-group .MuiInput-root {
    color: var(--Text, #1E1E1E);

    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 185.714% */
    border-radius: 8px;
    border: 1.2px solid #B4BEC8;
    height: 50px;
    padding: 10px 15px;
}

.payment-method-page .left-content-area .form-block .form-group .MuiInput-root::before,
.payment-method-page .left-content-area .form-block .form-group .MuiInput-root::after {
    display: none;
}

.payment-method-page .left-content-area .form-block .form-group label.checkbox-label {
    margin: 0;
    top: 0;
    padding: 0;
}

.payment-method-page .left-content-area .form-block .form-group select {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out;
    box-shadow: .15s ease-in-out;
}

.payment-method-page .left-content-area .form-block #accordion .card .card-body .btn-style-one {
    width: 100%;
}

.payment-method-page .left-content-area .form-block #accordion .card .card-body .btn-style-one .icon {
    width: 30px;
    display: inline-block;
    position: relative;
    top: -1px;
}

.payment-method-page .left-content-area .form-block #accordion .card .card-body .btn-style-one .icon svg {
    width: 17px;
}

.payment-method-page .left-content-area .form-block .form-group .scure-area {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 11px;
    padding: 18px 0 0;
    width: 100%;
}

.payment-method-page .left-content-area .form-block .form-group .scure-area .icon {
    margin-right: 9px;
}

.payment-method-page .left-content-area .form-block #accordion {
    position: relative;
}

.payment-method-page .left-content-area .form-block #accordion .card {
    padding: 0;
    border-radius: 0;
    border: none;
}

.payment-method-page .left-content-area .form-block #accordion .card .card-header {
    position: relative;
    background-color: transparent;
    border: none;
    padding: 0 0 11px 0;
    border-bottom: 1px solid #00000020;
}

.payment-method-page .left-content-area .form-block #accordion .card .card-header h5 {
    cursor: pointer;
    width: 100%;
    position: relative;
    display: block;
    text-decoration: none !important;
}

.payment-method-page .left-content-area .form-block #accordion .card .card-header h5 .btn-link {
    display: block;
    width: 100%;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    padding: 0;
    text-decoration: none !important;
}

.payment-method-page .left-content-area .form-block #accordion .card .card-header h5 .btn-link span {
    display: block;
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    position: relative;
}

.payment-method-page .left-content-area .form-block #accordion .card .card-header h5 .btn-link .icon {
    position: absolute;
    top: 2px;
    right: 0;
    transform: rotate(0deg);
    transition: all 0.3s ease-in-out;
}

.payment-method-page .left-content-area .form-block #accordion .card .card-header h5 .btn-link.collapsed .icon {
    transform: rotate(180deg);
}

.payment-method-page .left-content-area .form-block #accordion .card .card-body {
    padding: 0;
    margin-top: 21px;
}

.payment-method-page .left-content-area .form-block #accordion .card .card-body .listing-product {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.payment-method-page .left-content-area .form-block #accordion .card .card-body .listing-product .left-content {
    display: flex;
    align-items: center;
}

.payment-method-page .left-content-area .form-block #accordion .card .card-body .listing-product .left-content .img-box {
    position: relative;
    width: 40px;
    margin-right: 15px;
}

.payment-method-page .left-content-area .form-block #accordion .card .card-body .listing-product .left-content .img-box img {
    width: 40px;
    height: 40px;
    border-radius: 8px;
}

.payment-method-page .left-content-area .form-block #accordion .card .card-body .listing-product .left-content .text-box {
    position: relative;
}

.payment-method-page .left-content-area .form-block #accordion .card .card-body .listing-product .left-content .text-box h4 {
    color: #000;
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 125% */
    margin-bottom: 0;
}

.payment-method-page .left-content-area .form-block #accordion .card .card-body .listing-product .left-content .text-box p {
    margin-bottom: 0;
    color: var(--Secondry, #EF8A24);
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
}

.payment-method-page .left-content-area .form-block #accordion .card .card-body h5 {
    width: 100%;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    padding: 0;
    text-decoration: none !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.payment-method-page .left-content-area .form-block #accordion .card .card-body .form-group .btn-style-one {
    width: 100%;
}

.payment-method-page .left-content-area .form-block .form-group .total-number {
    background: #3C3F47;
    border: 0.7px solid #55cbc6;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A9AAAD;
    padding: 15px 35px;
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.payment-method-page .left-content-area .form-block .form-group .total-number input {
    border: 0 !important;
    background: transparent;
    box-shadow: none !important;
    outline: 0 !important;
    padding: 0 !important;
}

.payment-method-page .left-content-area .form-block .form-group .total-number .number {
    font-weight: 400;
    font-size: 16px;
    color: #A9AAAD;
    line-height: 70px;
    width: calc(100% - 120px);
    text-align: left;
}

.payment-method-page .left-content-area .form-block .form-group .total-number .count-wrap {
    /* border: 1px solid #55cbc6; */

    border-radius: 100px;
    padding: 7px 12px;
}

.payment-method-page .left-content-area .form-block .form-group .total-number .count-wrap span {
    font-weight: 700;
    font-size: 24px;
    background: linear-gradient(108deg, #3BE3B6 -60.82%, #6EB3D6 150.65%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    padding: 3px;
    margin: 0 10px;
}

.payment-method-page .left-content-area .form-block .form-group .total-number .count-wrap span:last-child {
    letter-spacing: -4px;
}