@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/*** 
====================================================================
		Reset
====================================================================
 ***/
* {
    margin: 0px;
    padding: 0px;
    border: none;
    outline: none;
    font-size: 100%;
}

/*** 
====================================================================
	Global Settings
====================================================================
***/
html {
    scroll-behavior: smooth;
}

body {
    font-size: 14px;
    color: #848484;
    line-height: 28px;
    font-weight: 400;
    background: #fff !important;
    background-color: #fff !important;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    font-family: 'Poppins', sans-serif;
}

#wrapper {
    background: #ffffff;
    background-color: #ffffff;
}

img {
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: #EF8A24;
}

a:hover,
a:focus,
a:visited {
    text-decoration: none !important;
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    position: relative;
    font-weight: normal;
    margin: 0px;
    background: none;
    line-height: 1.2em;
    font-family: 'Poppins', sans-serif;
}

textarea {
    overflow: hidden;
    resize: none;
}

figure {
    margin: 0;
    padding: 0;
}

button {
    outline: none !important;
    cursor: pointer;
}

p,
.text {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: #848484;
    margin: 0;
}

::-webkit-input-placeholder {
    color: inherit;
}

::-moz-input-placeholder {
    color: inherit;
}

::-ms-input-placeholder {
    color: inherit;
}

.btn-style-one {
    border-radius: 8px;
    text-transform: uppercase;
    background-color: #231916;
    border: 0;
    box-shadow: none;
    color: #fff;
    font-size: 14px;
    transition: .3s linear;
    position: relative;
    padding: 10px 25px;
    display: inline-block;
    height: 45px;
    line-height: 24px;
}

.btn-style-one:hover {
    background: #EF8A24;
    color: #fff !important;
    text-decoration: none;
    box-shadow: 0 0 0 2em transparent;
}
.btn-style-one:hover,
.btn-style-one:focus {
    box-shadow: 0 0 0 2em transparent;
}

.btn-style-two {
    border-radius: 8px;
    text-transform: uppercase;
    background-color: #fff;
    border: 0;
    box-shadow: none;
    color: #231916;
    font-size: 14px;
    transition: .3s linear;
    position: relative;
    padding: 10px 25px;
    display: inline-block;
    height: 45px;
    line-height: 24px;
}

.btn-style-two:hover {
    background: #EF8A24;
    color: #fff !important;
    text-decoration: none;
    box-shadow: 0 0 0 2em transparent;
}
.btn-style-two:hover,
.btn-style-two:focus {
    box-shadow: 0 0 0 2em transparent;
}

ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

ul li {
    list-style-type: none;
}

/* scrollbar styling */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    background-color: #252525;
}

a {
    cursor: pointer;
    text-decoration: none;
}

.sec-title {
    position: relative;
    padding-bottom: 25px;
}

.sec-title h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    color: var(--Primary, #231916);
}
.sec-title p {
    margin-bottom: 0;
    color: var(--Secondry, #EF8A24);
    font-family: "Poppins";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}
.auto-container {
  position: static;
  max-width: 80%;
  padding: 0px 15px;
  margin: 0 auto;
}
.payment-method-page .form .form-block .MuiFormControl-root .MuiInputBase-root input {
  border: none;
  padding: 10px;
}
/* ========== Main Modal Styles =========== */

.main-modal {
  max-width: 655px !important;
  top: 5%;
}

.main-modal .modal-header,
.modal-footer {
  border: none !important;
}

.main-modal .modal-content {
  text-align: center;
  position: relative;
  padding: 43px 20px;
  background: #ffffff;
  color: "#000000";
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  backdrop-filter: blur(20px);
  -o-backdrop-filter: blur(20px);
  -ms-backdrop-filter: blur(20px);
  -moz-backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 16px;
  
}

.main-modal .modal-content .modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
}

.main-modal .modal-content .modal-header .close {
  color: #030000 !important;
}

.main-modal .modal-content .modal-header .modal-logo {
  display: flex;
  justify-content: center;
}

.main-modal .modal-content .modal-header .modal-logo img {
  width: 35%;
  padding: 25px;
}

.main-modal .modal-content .modal-body {
  padding: 0px;
}

@media(max-width: 1450px) {
  .auto-container {
    max-width: 90%;
  }
}

@media(max-width: 1280px) {
  .auto-container {
    padding: 0 50px;
  }
}

@media(max-width: 1199px) {
}

@media(max-width: 767px) {
  .auto-container {
    padding: 0 30px;
  }
  .auto-container {
    max-width: 90%;
  }
}